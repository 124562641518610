import { render, staticRenderFns } from "./lawFirmHonors.vue?vue&type=template&id=52e464ea&scoped=true&"
import script from "./lawFirmHonors.vue?vue&type=script&lang=js&"
export * from "./lawFirmHonors.vue?vue&type=script&lang=js&"
import style0 from "./lawFirmHonors.vue?vue&type=style&index=0&id=52e464ea&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e464ea",
  null
  
)

export default component.exports