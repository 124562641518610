<template>
  <div>
    <div class="w1200">
      <div class="list">
        <div class="list_item flex_box" v-for="item in list" :key='item.id' @click="goDetails(item)">
          <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='fill' class="img" style="width: 240px; height: 150px"></el-image>
          <div class="flex1">
            <p class="time">
              <span>{{item.createTime}}</span>
            </p>
            <h3 class="title">{{item.honorTitle}}</h3>
            <p class="desc" v-html="item.subtitle"></p>
          </div>
        </div>
      </div>
    </div>
    <pagination ref='pagination' :total='total' @currentChange='currentChange'></pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[],
      total:0,
      params:{
        pageNum:1,
        pageSize:10
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList() {
      this.$api.getHonorByPage(this.params).then(res=>{
        this.list = res.rows
        this.total = res.total
      })
    },
    currentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    goDetails(item) {
      this.$router.push({
        path:'/lawFirmHonorsDetails/'+item.id,
        query:{
          type:this.$route.query.type
        }
      })
    }
  }
}
</script>

<style lang='stylus' scoped>
@import '~@/assets/stylus/variable'
  .list_item
    padding 40px 40px 30px 0
    border-bottom: 1px dashed #bbb
    cursor pointer
    &:hover
      .title
        color $theme-color
      .iconfont
        background: $theme-color
        border-color $theme-color
        color #fff
    .img
      margin-right 36px
    .time
      font-size 16px
      color #838383
    .title
      font-size 22px
      color #333333
      margin 15px 0
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
    .desc
      font-size 16px
      color #666666
      line-height: 30px
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
</style>